<template>
    <div class="container-box">
        <div class="top-wrap">
            <div class="formItem">
                <p class="title"><b>报名信息确认</b></p>
                <div class="itemWrap">
                    <div class="listItem">
                        <div class="item iTitle">本次报名人数</div>
												<div class="item input-container"><span style="color: #999;" >{{ formItem.number }}人</span></div>
                        <div class="item iTitle">本次报名组数</div>
                        <div class="item input-container"><span style="color: #999;" >{{ formItem.team_num }}组</span></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">竞赛单人费用</div>
                        <div class="item input-container"><span style="color: #999;" >{{ formItem.sFeeNum }}元</span></div>
                        <div class="item iTitle">本次上传应缴费</div>
                        <div class="item input-container"><span style="color: #999;" >{{ formItem.money }}元</span></div>
                    </div>
                    
                    <div class="listItem">
                    </div>
                    <div class="listItem">
                    </div>
										<div class="listItem" style="border:none; width: 100%; text-align: center; height: 0.4rem; line-height: 0.4rem; align-items: center; justify-content: center;">
											<div class="download-button" type="primary" @click="Download">"大唐杯"参赛委托代收证明下载</div>
                    </div>
                </div>
            </div>
        </div>
				<div class="btn-wrap">
					<div style="font-size: 0.2rem;">请选择缴费方式</div>
					<el-button class="btn" :style="getButtonStyle('staticState')" type="primary" 
					@click="switchType('staticState')">转账汇款</el-button>
					<el-button class="btn" :style="getButtonStyle('dynamics')" type="primary" 
					@click="switchType('dynamics')">二维码支付(微信/支付宝/信用卡/公务卡等)</el-button>
					<div class="line"> </div>
				</div>
        <div class="bottom-wrap" v-if="this.activeButton == 'staticState'">
            <div class="left-wrap">
                <div class="formItem">
                    <!-- 支付方式 -->
                    <p class="title "><b></b></p>
                    <div class="upload">
                        <el-upload ref="upload" class="avatar-uploader" action="" :show-file-list="false"
                            :on-remove="handleRemove" :on-change="onChange" :on-success="handleUploadSuccess"
                            :before-upload="beforeAvatarUpload" :http-request="httpRequest" :file-list="fileList">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </div>
                    <span class="upload-title">点击上传支付凭证</span>
                </div>

                <div class="payment">
                    <p class="payMode">报名支付方式</p>
                    <p class="typeface font_style">方式一(转账支付):</p>
                    <p class="typeface">收费账户/姓名:</p>
                    <p class="typeface">{{ paymentCompany }}</p>
                    <p class="typeface">开户银行:</p>
                    <p class="typeface">{{ bankDeposit }}</p>
                    <p class="typeface">转账账号:</p>
                    <p class="typeface">{{ transferAccount }}</p>
                    <p class="typeface typefaceStyle">转账务必请备注: 学校+人数</p>
										<p class="typeface PayRemark">(暂不支持云闪付)</p>
                </div>
                <div class="vertical-line"></div>
            </div>
            <div class="center-wrap">
                <el-button v-loading="loading" @click="save">提交</el-button>
            </div>
				</div>
				<!-- 动态二维码支付 -->
				<div class="bottom-wrap" v-if="this.activeButton == 'dynamics'">
					<div class="payInfo">
						<span>扫一扫支付(元)</span>
						<span style="color: rgb(230, 120, 23); margin-top: 10px;"> {{ formItem.money?formItem.money:0 }}.00 </span>
					</div>
					<div class="left-wrap">
            <div class="qr-payment-page">
							<div  v-if="isShow1()" class="qr-container">
								<div class="countdown-timer">
									请在倒计时内完成支付: {{ countdown }} 秒
								</div>
								<img :src="qrCodeUrl" alt="支付二维码" class="qr-code" />
								<div class="countdown-timer">
									请勿刷新页面，等待支付完成
								</div>
								<div class="info">
									<span style="color: rgb(17, 140, 71);"> {{ paymentCompany }} </span>
									<span style="color: rgb(17, 140, 71);"> 支持微信/支付宝/信用卡/公务卡等 </span>
									<span style="color: rgb(196, 45, 37);"> 缴费请备注:学校全称+报名人数 </span>
									<span style="height: 5px;"> </span>
								</div>
							</div>
							<!-- 二维码失效 -->
							<div v-if="isShow2()" class="timeout-message">
								<span> 支付已超时 </span>
								<span> 请刷新页面重新获取二维码 </span>
								<el-button class="refreshBtn" type="primary" @click="refreshQRCode">刷新
								</el-button>
							</div>
							<!-- 支付成功 -->
							<div class="pay-success" v-show='isPaySuccess' @click="isPaySuccess = !isPaySuccess">
								<img class="pay-success-img" src="../../assets/images/back/paySuccess.png" alt="支付成功" />
								<span style="font-weight: bold; font-size: 0.2rem;"> 报名成功，请等待审核，1-2个工作日内开通学唐账号 </span>
							</div>
						</div>
					</div>
				</div>
    </div>
</template>
<script>
import Input from './textInput.vue';
import { Plus } from '@element-plus/icons-vue';
import { httpRequest } from '@/tools/common.js'
import { userInfo, qrcodeInfo } from "api/apis.js";
import { ElMessage } from 'element-plus'
import { Debounce } from '@/tools/common.js'
import { el } from 'element-plus/es/locale';
export default {
    name: 'Step',
    components: {
        Input,
        Plus
    },
    data() {
        return {
            text: '',
            imageUrl: '',
            dialogImageUrl: '',
            dialogVisible: true,
            boyaNewStatePaymentInfo: false,
            tjPaymentInfo: false,
            openInvoicePaymentInfo: false,
            info: true,
            time: new Date(),
						paymentCompany: '',
						bankDeposit: '',
						transferAccount: '',
            fileList: [],
            groupList: [],
            // url: '',
            pay_pic_path: '',
            formItem: {
                number: '', //报名人数
                // groupNum: '',
                sFeeNum: '',
                money: '', //应缴费
                team_num: '',
                invoice_content: '',//发票类型
                invoice_title: '', //发票抬头
                invoiceHeader: '',
                invoice_num: '',//税号
                invoice_way: '',
                memo: '',//备注
                invoice4: '',
                invoice_address: '',//发票邮寄地址
                other2: '',
                invoiceType: true
            },
            is_need_bill: '',
            loading: false,
            payment_unit_price: 120,
            totalNum: 0,//应缴费
            options: [
                {
                    value: 'Option1',
                    label: '微信',
                },
                {
                    value: 'Option2',
                    label: '支付宝',
                },
            ],
            query: {},
            paymentType: '',
						activeButton: 'staticState', 

						countdown: 600, // 倒计时初始值（600秒）
						qrCodeUrl: '',  // 存储二维码的URL
						countdownInterval: null,
						isPaySuccess: false,
						getUrl: '',
						payment_id: '',
						timer: null,
						cmbOrderId: '',
        }
    },
    created() {
        //获取单人价格
        this.InitPrice();
        this.query = this.$route.query;
        const { total_Obj } = this.$route.query;
        const { team_nums, nums, payment_id } = JSON.parse(total_Obj);
        this.totalNum = Number(nums) * Number(this.payment_unit_price);
        // this.formItem.money = this.totalNum;
        // this.formItem.team_num = team_nums;
        // this.formItem.number = nums;
				this.formItem.sFeeNum = this.payment_unit_price;
				// this.payment_id = payment_id
        this.getPayInfo(payment_id);
    },
		mounted() {
		},
    methods: {
        InitPrice() {
            userInfo.price().then(res => {
                this.payment_unit_price = res.payment_unit_price
            })
        },
				getPayInfo(payment_id) {
					let dataobj = new FormData();
					dataobj.append("payment_id", payment_id);//payment_id
					userInfo.getPayInfo(dataobj).then(res => {
								if( res.code == 200 ) {
									let data = JSON.parse(res.data)
									this.formItem.team_num = data.team_num //组数
									this.formItem.number = data.number; //报名人数
									this.formItem.money = data.money; //缴费总价
									this.payment_id = data.payment_id
									this.getPaymentInfo(this.payment_id)
									console.log(data)
								}
            })
        },
        //获取回显信息
        getMonitor(val, type, isFormat) {
            if (type == 'enrollment') { //报名人数
                this.formItem.number = `${val}`;
            } else if (type == 'numberGroups') { //组数
                this.formItem.team_num = `${val}`;
            } else if (type == 'unitPrice') { //缴费单价
                this.formItem.sFeeNum = `${val}`;
            } else if (type == 'totalPrice') { //缴费总价
                this.formItem.money = `${val}`;
            } else if (type == 'invoiceText') { //发票内容
                if (!val) {
                    this.boyaNewStatePaymentInfo = false;
                    this.openInvoicePaymentInfo = false;
                    this.tjPaymentInfo = false;
                    this.info = true
                }
                else {
                    this.boyaNewStatePaymentInfo = false;
                    this.tjPaymentInfo = false;
                    this.openInvoicePaymentInfo = true;
                    this.info = false
                    this.is_need_bill = '是';
                    if (`${val}` == '现代服务*报名费') {
                        let groups = ["中职", "高职"];
                        this.boyaNewStatePaymentInfo = false;
                        this.openInvoicePaymentInfo = false;
                        this.tjPaymentInfo = false;
                        const { engineeringPractice, innovation } = this.$route.query;
                        let paractice = JSON.parse(engineeringPractice);
                        let innovationInfo = innovation;
                        let groups_enginnering = [];
                        paractice.forEach(element => {
                            groups_enginnering.push(element.group)

                        });
                        let groups_innovation = []
                        innovationInfo.forEach(element => {
                            groups_innovation.push(element.group)

                        });
                        const diffSet = groups_enginnering.concat(groups_innovation).filter(item => !groups.includes(item));

                        if (diffSet.length > 0 && groups_innovation.length == 0) {
                            this.tjPaymentInfo = true;
                        }
                        else {
                            this.boyaNewStatePaymentInfo = true;
                        }
                    }
                    else if (`${val}` == '不开发票') {
                        this.is_need_bill = '否';
                    }


                    this.formItem.invoice_content = `${val}`;
                }

            }
            else if (type == 'invoiceHead') { //发票抬头
                this.formItem.invoice_title = `${val}`;
            }
            else if (type == 'taxNumber') { //税号
                this.formItem.invoice_num = `${val}`;
            }
            else if (type == 'invoiceNotes') { //发票备注栏信息
                this.formItem.memo = `${val}`;
            }
            //电子邮箱
            else if (type == 'email') { //邮箱
                this.formItem.invoice_address = `${val}`;
                sessionStorage.setItem('FP4', `${val}`);
            }


        },
        //提交缴费
        save: Debounce(function () {
            if (this.pay_pic_path == '') {
                ElMessage({
                    message: "请上传支付凭证",
                    type: "error",
                });
                return
            }
            let team_ids = this.query.team_ids
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            if (this.formItem.invoice_content == '不开发票') {
                this.formItem.invoice_num = ''
                this.formItem.memo = ''
                this.formItem.invoice_address = ''
                this.formItem.invoice_way = ''
                this.formItem.invoice_title = ''
            }
            dataobj.append("user_id", uid);//用户id
						dataobj.append("payment_id", this.payment_id);//payment_id
            // dataobj.append("number", this.formItem.number);//报名人数
            // dataobj.append("team_num", this.formItem.team_num);//组数
            // dataobj.append("money", this.formItem.money);//费用
            // dataobj.append("is_need_bill", this.is_need_bill);//
            // dataobj.append("invoice_title", this.formItem.invoice_title);//发票抬头
            // dataobj.append("invoice_num", this.formItem.invoice_num);//税号
            // dataobj.append("invoice_content", this.formItem.invoice_content);
            // dataobj.append("invoice_way", '电子发票');
            // dataobj.append("memo", this.formItem.memo);
            // dataobj.append("invoice_address", this.formItem.invoice_address);
            dataobj.append("pay_pic_path", this.pay_pic_path); //图片路径
            // dataobj.append("team_ids", JSON.stringify(team_ids)); //团队id
            // dataobj.append("company_info",this.paymentType ); //团队id
            this.loading = true;
            userInfo.paySubmit(dataobj).then(res => {
                this.loading = false;
                if (res.code === 200) {
                    ElMessage({
                        message: "提交成功",
                        type: "success",
                    });
                    this.$router.push('/auditInformation');
                } else {
                    ElMessage({
                        message: res.msg,
                        type: "success",
                    });

                    this.$router.push('/auditInformation');
                }
            })
        }, 1000),
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        // 上传的方法
        onChange(file, fileList) {
            this.fileList = fileList
        },
        handleUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList, 'response, file, fileList')
        },
        handleRemove(e, fileList) {
            this.fileList = fileList;
        },
        beforeAvatarUpload(file) {
            // 文件大小校验
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.error('文件大小不能超过 10M !');
            }
            return isLt2M;
        },

        //回调接收上传成功的参数
        getHttpRelut(res) {
            //业务逻辑
            this.imageUrl = res.url;
            this.pay_pic_path = res.url;
        },
        //上传时的oss 
        // user_register// 用户注册
        // sign_up_audit// 审核 
        httpRequest(file) {
            httpRequest(file, "sign_up_audit", this.getHttpRelut);
        },
        // 文件下载
        Download() {
            window.open('./static/ZhenM/第十二届大唐杯参赛费代收证明-新.pdf');
        },

        //缴费公司信息查询
        getPaymentInfo(payment_id) {
					let dataobj = new FormData();
					dataobj.append("payment_id", payment_id);//payment_id
            userInfo.paymentInfo(dataobj).then((res) => {
                if (res.code === 200) {
                    this.paymentCompany = JSON.parse(res.data)['收费账户/姓名'] || '';
										this.bankDeposit = JSON.parse(res.data)['开户银行'] || '';
										this.transferAccount = JSON.parse(res.data)['转账账号'] || '';
                    console.log(JSON.parse(res.data),'paymentCompanyInfo')
                }
            })
        },

				// 判断支付框是否隐藏
				isShow1(type) {
					return !this.isPaySuccess && this.countdown > 0;
				},
				isShow2(type) {
					return !this.isPaySuccess && this.countdown <= 0;
				},

				// 缴费模式按钮模式点击事件
				switchType(button) {
					this.activeButton = button; // 将当前点击的按钮标识设为激活状态
					// 切换为二维码支付时，显示二维码并开始倒计时
					if( button == 'dynamics' ) {
						if( !this.countdownInterval ) {
							this.getGrcode()
							this.startCountdown();
						}
					}
				},
				getButtonStyle(button) {
					if (this.activeButton === button) {
					  return {
							background: "linear-gradient(to bottom, #9cdb96, #007b2c)",
						};
					}
					return {
							background: 'linear-gradient(to bottom, #9cc6e0, #1b75bc)',
						}
				},
				// 获取二维码
				getGrcode() {
					qrcodeInfo.grcode({paymentCompany: this.paymentCompany,paymentId: this.payment_id,money: this.formItem.money, user_id: this.$store.state.User.user_id}).then(res=>{
						if( res.status == 'SUCCESS' ) {
							this.getUrl = res.images
							this.cmbOrderId = res.cmbOrderId
							this.generateQRCode();
							setTimeout(() => {
							  this.startPolling()
							},15000)
						} else {
						  ElMessage.error('获取二维码失败!')
						}
					})
				},
				// 模拟生成二维码的功能
				generateQRCode() {
      		// 根据接口返回信息生成二维码
					this.qrCodeUrl = `data:image/png;base64,${this.getUrl}`;
				},
				// 开始倒计时
				startCountdown() {
					this.countdownInterval = setInterval(() => {
						if (this.countdown > 0) {
							this.countdown--;
						} else {
							clearInterval(this.countdownInterval);
							this.stopPolling(); // 组件销毁前清除定时器
							this.closeOrder()
						}
					}, 1000);
				},
				// 关闭订单
				closeOrder() {
					qrcodeInfo.closeOrder({ paymentCompany: this.paymentCompany, cmbOrderId: this.cmbOrderId }).then(res=>{
						console.log(res,`关闭订单--${this.cmbOrderId}`);
					})
				},
				// 刷新二维码并重置倒计时
				refreshQRCode() {
					this.countdown = 600; // 重置倒计时为600秒
					this.getGrcode(); // 重新生成二维码
					clearInterval(this.countdownInterval); // 清除之前的计时器
					this.startCountdown(); // 重新启动倒计时
				},
				// 查询支付状态
				getPayState() {
				  qrcodeInfo.getPayState({ paymentId: this.payment_id, cmbOrderId: this.cmbOrderId }).then(res=>{
						console.log(res);
						if(res.status == 'SUCCESS'){
							this.isPaySuccess = true
							setTimeout(() => {
								this.$router.push('/auditInformation');
							}, 2000);
						}
					})
				},
				// 开始计时
				startPolling() {
					this.getPayState(); // 立即执行一次
					this.timer = setInterval(() => {
						this.getPayState();
					}, 3000); // 每隔3秒调用一次
				},
				// 清除计时
				stopPolling() {
					if (this.timer) {
						clearInterval(this.timer);
						this.timer = null;
					}
				},
    },
		beforeDestroy() { 
			clearInterval(this.countdownInterval); // 在组件销毁时清除计时器
			this.stopPolling(); // 组件销毁前清除定时器
		},
		beforeRouteLeave(to, from, next) {
			clearInterval(this.countdownInterval); // 在组件销毁时清除计时器
			this.stopPolling(); // 组件销毁前清除定时器
			next();
		},
    handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
    }
}
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 3.14rem;
    height: 3rem;
    display: block;
}
</style>
<style lang="scss" scoped>
:deep(.el-select .el-input__wrapper.is-focus) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

:deep(.el-select .el-input.is-focus .el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

:deep(.el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
    border: none;

    .el-input__inner {}
}

:deep(.el-textarea__inner) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
    resize: none;
}


:deep(.el-upload) {
    width: 3.14rem !important;
    height: 2.83rem !important;
}

:deep(.el-select) {
    margin-bottom: 0.2rem;

}

.payment :deep(.el-input__wrapper) {
    background-color: #F5F7FA;
    -webkit-box-shadow: 0px 0.06rem 3px #c8c8c8;

    -moz-box-shadow: 0px 0.06rem 3px #c8c8c8;

    box-shadow: 0px 0.06rem 3px #c8c8c8;

}

:deep(.el-button) {
    width: 1.71rem;
    height: 0.45rem;
    background-color: #436E8A;
    color: white;
    font-size: 0.24rem;
}

.avatar-uploader {
    border: 1px dashed #dcdfe6;
}

.avatar-uploader:hover {
    border-color: 1px dashed #409eff;
}

.input-container {
	position: relative;
	display: inline-block;
	.unit {
		position: absolute;
		top: 50%;
		right: 10px; /* 调整这个值以确定单位的位置 */
		transform: translateY(-50%);
		font-size: 14px; /* 根据需要调整字体大小 */
		color: #999; /* 可选：调整颜色 */
		pointer-events: none; /* 防止单位影响用户交互 */
	}
}

.avatar-uploader .el-upload:hover {
    border-color: 1px dashed #409eff;
}

.avatar-uploader .el-upload {
    border: 1px dashed #dcdfe6;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

@import './index.scss';
</style>